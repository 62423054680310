<template>
  <LayoutFilter
    :onFilter="ok"
    :onReset="resetForm"
    :collapseHeight="120"
    :toggleCollapseEnable="false"
    :onExport="onExport"

  >
    <el-form
      :model="form"
      ref="logFilterForm"
      label-width="70px"
      size="medium"
      class="filter-from"
      @submit.native.prevent
    >
      <!-- 模块&用户名&搜索 -->

      <!-- 模块&用户名&搜索 -->
      <div class="filter-item">
        <el-form-item label="分类名称：" prop="keyword">
          <el-input
            v-model="form.keyword"
            placeholder="请输入分类名称"
          ></el-input>
        </el-form-item>
      </div>
      <div class="filter-item">
        <DatePeriodPicker
          label="创建时间："
          start-prop="create_start_time"
          :start-time.sync="form.create_start_time"
          end-prop="create_end_time"
          :end-time.sync="form.create_end_time"
        />
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from '@/base/layout/LayoutFilter'
import DatePeriodPicker from '@/base/components/Form/DatePeriodPicker'
import { exportFile } from '@/modules/college/api/learn-category-list.js'

export default {
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    filterForm: {
      type: Object,
    },
    idList: {
      type: Array,
    },
  },
  data() {
    return {}
  },
  methods: {
    //重置表单
    resetForm() {
      this.$refs['logFilterForm'].resetFields()
      this.uploadFilter(this.form)
    },
    //筛选
    ok() {
      this.uploadFilter(this.form)
    },
    //导出
    onExport() {
      return exportFile({
        export_ids: this.idList,
        ...this.form,
      }).catch(() => {})
    },
  },
  created() {},
  computed: {
    form: {
      get() {
        return this.filterForm
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
}
</script>
