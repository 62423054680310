<!--
 * @Author: dongjia
 * @Date: 2021-08-05 15:24:32
 * @LastEditTime: 2022-01-07 15:27:40
 * @LastEditors: aleaner
 * @Description: 专题栏目分类列表
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\brand-admin\views\brand-category.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="list-page" v-loading="loading">
    <el-button
      class="top-btn"
      size="small"
      type="primary"
      @click="handleAddCategory"
      >添加分类</el-button
    >
    <CategoryFilter
      :filterForm="filterForm"
      :uploadFilter="RefreshList"
      :idList="selectArr"
    />
    <list-tabs
      v-model="filterForm.tab"
      :tabs="tabArr"
      :isLoading="loading"
      @tab-click="handleClick"
    >
    </list-tabs>
    <div class="list-content" v-loading="loading">
      <div class="list-btn" v-if="filterForm.tab === 'normal'">
        <!-- <el-button type="warning" size="small" @click="handleJoin">加入组织</el-button> -->
        <el-button type="danger" size="small" @click="handleDel"
          >删除</el-button
        >
      </div>
      <div class="list-btn" v-if="filterForm.tab === 'deleted'">
        <el-button type="success" size="small" @click="handleReduct"
          >还原</el-button
        >
        <el-button type="danger" size="small" @click="handleDel"
          >删除</el-button
        >
      </div>
      <el-table
        id="sortTable"
        row-key="id"
        :data="categoryList"
        class="thead-light"
        v-loading="showLoading"
        stripe
        @sort-change="sortChange"
        @selection-change="handleSelectionChange"
      >
        <!-- 勾选-->
        <el-table-column fixed type="selection" width="55"></el-table-column>
        <el-table-column
          v-for="(item, index) in categoryTable.filter(
            (c) => c.visible === undefined || c.visible(filterForm)
          )"
          :key="index"
          :label="item.label"
          :prop="item.prop"
          :min-width="item.min_width"
          :sortable="item.sortable"
        >
          <template slot-scope="scope">
            <!--排序 -->
            <div v-if="item.prop === 'sort'">
              <i class="el-icon-sort cursor-pointer"></i>
            </div>

            <el-checkbox
              v-else-if="item.prop === 'status'"
              v-model="scope.row[item.prop]"
              :true-label="1"
              :false-label="0"
              @change="changeStatus(scope.row)"
            >
            </el-checkbox>
            <span v-else>{{ scope.row[item.prop] | placeholder }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          :width="filterForm.tab === 'normal' ? '180' : '120'"
          fixed="right"
        >
          <template slot-scope="scope">
            <div v-if="filterForm.tab === 'normal'">
              <el-button
                style="color: #3576ff"
                size="small"
                type="text"
                @click="handleEditCategory(scope.row)"
                >编辑
              </el-button>
              <el-button
                style="color: #3576ff"
                size="small"
                type="text"
                @click="handleTransfer(scope.row)"
                >转移
              </el-button>
              <el-button
                style="color: #3576ff"
                size="small"
                type="text"
                @click="handleDelCategory(scope.row)"
                >删除
              </el-button>
            </div>
            <div v-else>
              <el-button
                style="color: #3576ff"
                size="small"
                type="text"
                @click="handlePutback(scope.row.id)"
              >
                还原
              </el-button>
              <el-button
                style="color: #3576ff"
                size="small"
                type="text"
                @click="handleDelCategory(scope.row)"
                >删除
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <AddCategory
      v-model="openAddDialog"
      :Data="CategoryData"
      :updateData.sync="CategoryData"
      :updateList="RefreshList"
    />
    <TransferClassify
      v-model="showTransfer"
      :Data="TransferData"
      :categoryList="categoryOptions"
      :updateData.sync="TransferData"
      :updateList="RefreshList"
    />
  </div>
</template>

<script>
import Sortable from 'sortablejs/modular/sortable.core.esm.js'

import CategoryFilter from '../components/LearnCategory/CategoryFilter'
import PopoverInputEdit from '@/base/components/Popover/PopoverInputEdit'
import {
  categoryList,
  updateShow,
  softDelete,
  updateSort,
  putBack,
  del,
} from '../api/learn-category-list'

import AddCategory from '../components/LearnCategory/AddClassify'
import TransferClassify from '../components/LearnCategory/TransferClassify'
export default {
  name: 'circle-category',
  data() {
    return {
      loading: false,
      tabArr: [
        { label: '全部', name: 'normal' },
        { label: '回收站', name: 'deleted' },
      ],
      filterForm: {
        tab: 'normal',
        keyword: '',
        create_start_time: -1,
        create_end_time: -1,
        page_size: 15,
      },
      filters: {
        normal: null,
        deleted: null,
      },

      categoryTable: [
        {
          label: '排序',
          prop: 'sort',
          min_width: 100,
          visible: (filterForm) => filterForm.tab !== 'deleted',
        },
        { label: 'ID', prop: 'id', min_width: 100 },
        { label: '分类', prop: 'name', min_width: 100 },
        {
          label: '关联内容数量',
          prop: 'data_count',
          min_width: 150,
          sortable: true,
        },
        {
          label: '创建时间',
          prop: 'create_time',
          min_width: 160,
          sortable: true,
        },
      ],
      validator: {
        type: 'integer',
        min: 1,
        message: '最小不能低于1的整数',
        trigger: 'blur',
      },
      // 转移弹窗数据
      TransferData: {
        name: '',
        id: '',
        target_id: '',
      },
      selectArr: [],
      brandsCountArr: [],
      categoryList: [],
      categoryOptions: [],
      showLoading: false,
      openAddDialog: false,
      CategoryData: {
        id: '',
        name: '',
      },
      showTransfer: false,
      transferId: '',
    }
  },
  components: {
    CategoryFilter,
    AddCategory,
    TransferClassify,
    PopoverInputEdit,
  },
  created() {
    this.getCategoryList(this.filterForm)
  },
  mounted() {
    // 初始化拖拽表格
    this.initSortable()
  },
  methods: {
    // 单项删除
    handleDelCategory(data) {
      let delTitle = ''
      let delBtn = ''
      let type = ''
      if (this.filterForm.tab === 'normal') {
        if (data.brands_count > 0) {
          delTitle = '该分类下还有信息内容，建议先进行内容转移，再删除'
          delBtn = '仍要删除'
          type = 'error'
        } else {
          delTitle = '确认要将该分类删除吗？'
          delBtn = '删除'
          type = 'info'
        }
      } else {
        delTitle = '确认要将该分类删除吗？删除后无法恢复'
        delBtn = '删除'
        type = 'error'
      }
      this.$confirm(delTitle, '提示', {
        confirmButtonText: delBtn,
        cancelButtonText: '取消',
        type: type,
      })
        .then((res) => {
          this.showLoading = true
          if (this.filterForm.tab === 'normal') {
            this.softDelete([data.id])
          } else {
            this.Delete([data.id])
          }
        })
        .catch((err) => {})
    },
    // 单项还原
    handlePutback(data) {
      this.showLoading = true
      this.putBack([data.id])
    },
    //批量删除
    handleDel() {
      let delTitle = ''
      let delBtn = ''
      let type = ''
      if (this.selectArr.length === 0) {
        this.$message.info('请选择数据项')
        return
      }
      if (this.filterForm.tab === 'normal') {
        if (this.brandsCountArr.findIndex((el) => el > 0) !== -1) {
          delTitle = '部分分类下还有信息内容，建议先进行内容转移，再删除'
          delBtn = '仍要删除'
          type = 'error'
        } else {
          delTitle = '确认要将这些分类删除吗？'
          delBtn = '删除'
          type = 'info'
        }
      } else {
        delTitle = '确认要将将这些分类删除吗？删除后无法恢复'
        delBtn = '删除'
        type = 'error'
      }
      this.$confirm(delTitle, '提示', {
        confirmButtonText: delBtn,
        cancelButtonText: '取消',
        type: type,
      })
        .then((res) => {
          this.showLoading = true
          if (this.filterForm.tab === 'normal') {
            this.softDelete(this.selectArr)
          } else {
            this.Delete(this.selectArr)
          }
        })
        .catch((err) => {})
    },
    // 批量还原
    handleReduct() {
      if (this.selectArr.length === 0) {
        this.$message.info('请选择数据项')
        return
      }
      this.showLoading = true
      this.putBack(this.selectArr)
    },
    //软删除
    softDelete(ids) {
      softDelete({ id: ids })
        .then((res) => {
          this.$message.success(res.msg)
          this.RefreshList()
        })
        .catch((err) => {
          this.showLoading = false
        })
    },
    // 真删除
    Delete(ids) {
      del({ id: ids })
        .then((res) => {
          this.$message.success(res.msg)
          this.RefreshList()
        })
        .catch((err) => {
          this.showLoading = false
        })
    },
    // 还原
    putBack(ids) {
      putBack({ id: ids })
        .then((res) => {
          this.$message.success(res.msg)
          this.RefreshList()
        })
        .catch((err) => {
          this.showLoading = false
        })
    },
    // 添加分类
    handleAddCategory() {
      this.CategoryData = {
        id: '',
        name: '',
      }
      this.openAddDialog = true
    },
    handleEditCategory(data) {
      this.CategoryData = {
        id: data.id,
        name: data.name,
      }
      this.openAddDialog = true
    },
    updateData() {
      this.openAddDialog = false
      this.RefreshList()
    },
    changeShow(id, e) {
      let data = {
        id: id,
        is_show: e,
      }
      this.showLoading = true
      updateShow(data)
        .then((res) => {
          this.$message.success(res.msg)
          this.RefreshList()
        })
        .catch((err) => {
          this.showLoading = false
        })
    },

    //更新显示状态
    changeStatus(row) {
      const data = {
        id: row.id,
        status: row.status,
      }
      this.showLoading = true
      updateShow(data)
        .then((res) => {
          this.$message.success(res.msg)
          this.RefreshList()
        })
        .catch((err) => {
          this.refreshList()
        })
    },
    //点击转移
    handleTransfer(row) {
      this.TransferData = {
        name: row.name,
        id: row.id,
        target_id: '',
      }
      this.showTransfer = true
    },
    // 勾选操作
    handleSelectionChange(selection) {
      if (!selection.length) {
        this.selectArr = []
        this.brandsCountArr = []
      } else {
        this.selectArr = []
        this.brandsCountArr = []
        // 筛选id
        selection.forEach((item) => {
          this.selectArr.push(item.id)
          this.brandsCountArr.push(item.brands_count)
        })
      }
    },
    // 切换tab栏
    handleClick(e) {
      if (this.filters[e.name]) {
        this.filterForm = this.filters[e.name]
      } else {
        this.filterForm = {
          keyword: '',
          create_start_time: -1,
          create_end_time: -1,
          tab: e.name, //当前筛选tab
        }
      }
      this.getCategoryList(this.filterForm)
    },
    //表格排序
    sortChange(column) {
      this.filterForm.is_desc = !column.order
        ? -1
        : column.order === 'ascending'
        ? 0
        : 1
      this.filterForm.order_by = column.prop
      this.getCategoryList(this.filterForm)
    },
    changPage(data) {
      this.filterForm.page_size = data.page_size
      let requestData = { ...this.filterForm, ...data }
      this.getCategoryList(requestData)
    },
    // 刷新列表
    RefreshList() {
      const data = {
        ...this.filterForm,
      }
      this.getCategoryList(data)
    },
    getCategoryList(requestData) {
      this.showLoading = true

      categoryList(requestData)
        .then((res) => {
          console.log(res, 'res')
          this.categoryList = res.data.list

          this.showLoading = false
        })
        .catch((err) => {})
    },
    saveCateGory(ids) {
      updateSort({ ids }).then((res) => {
        this.$message.success(res.msg)
        this.RefreshList()
      })
    },
    //初始化拖拽表格
    initSortable() {
      this.$nextTick(() => {
        const sortTable = document.querySelector(
          '#sortTable .el-table__body>tbody'
        )
        console.log(sortTable)
        this.$sortTable = Sortable.create(sortTable, {
          animation: 150,
          handle: '.el-icon-sort',
          ghostClass: 'sortable-ghost',
          onEnd: (event) => {
            console.log(event.oldIndex, event.newIndex)
            // 拿到拖拽的item
            const oldItem = this.categoryList[event.oldIndex]
            // 删除原本位置
            this.categoryList.splice(event.oldIndex, 1)
            // 添加到新的位置
            this.categoryList.splice(event.newIndex, 0, oldItem)
            let idArray = this.categoryList.map((item) => item.id)
            //保存拖拽排序
            this.saveCateGory(idArray)
          },
        })
        //阻止火狐拖拽新建新页面
        document.body.addEventListener('drop', this.preventDrop, false)
      })
    },
    //销毁拖拽表格
    destroySortable() {
      if (this.$sortTable) this.$sortTable.destroy()
      document.body.removeEventListener('drop', this.preventDrop, false)
    },
    //阻止默认drop事件
    preventDrop(event) {
      event.preventDefault()
      event.stopPropagation()
    },
  },
  beforeDestroy() {
    //销毁拖拽表格
    this.destroySortable()
  },
}
</script>

<style scoped lang="scss"></style>
